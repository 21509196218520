import Highcharts from "highcharts";

$( document ).on('turbolinks:load', function() {
    console.log("Im in jquery of statisticsss...")
    $('.entity-type-btn').on('click', function(){
        console.log("entity type button clieck...")
        //$('#choose-report-type-label').hide(50);
        if ($('#entity_type_Institucionit').is(':checked')) {
            //$('#choose-entity-label').show(50);
            //$('#choose-entity-label').addClass('text-right');
            //$('#choose-institution').show(50);
            //$('#choose-ammount-or-number-label').show(50);
            //$('#choose-all-entities-chart-type').show(50);
            //$('#choose-ammount-or-number').hide(50);
            //$('#choose-city').hide(50);
            //$('#choose-agent').hide(50);
            //$('#submit-button-div').show(50);
            $('#draw-charts-button').attr('data-type', 'institution')
        } else if ($('#entity-type-division').is(':checked')) {
            $('#choose-entity-label').show(50);
            $('#choose-entity-label').addClass('text-right');
            $('#choose-city').show(50);
            $('#choose-ammount-or-number-label').show(50);
            $('#choose-all-entities-chart-type').show(50);
            $('#choose-ammount-or-number').hide(50);
            $('#choose-institution').hide(50);
            $('#choose-agent').hide(50);
            $('#submit-button-div').show(50);
            $('#draw-charts-button').attr('data-type', 'division')
        } else if ($('#entity-type-agent').is(':checked')) {
            $('#choose-entity-label').show(50);
            $('#choose-entity-label').addClass('text-right');
            $('#choose-agent').show(50);
            $('#choose-city').hide(50);
            $('#choose-institution').hide(50);
            $('#submit-button-div').show(50);
            $('#draw-charts-button').attr('data-type', 'agent')
        }
    });

    $('.entity-selector').on('change', function(){
      $('#draw-charts-button').attr('data-entity-id', $(this).val())
    })



    $('#submit').on('click', function(){
        //var chart_type = 'columns';
        var report_type = get_report_type();
        var amount_or_number = get_amount_or_number();
        var entity = ''
        if ( report_type == 'institucionit') {
            entity = $('#bank_id').val();
        } else if (report_type == 'divizionit') {
            entity = $('#city_id').val();
        } else if (report_type == 'agjentit') {
            entity = $('#user_id').val()
        }
        console.log("entity is: " + entity);
        console.log("amount_or_number is: " + amount_or_number)

        $.ajax({
            //method: 'GET',
            //url: '/get_statistics_data',
            //data: { report_type: report_type, entity: entity, sipas: amount_or_number},
            success: function (data) {
                console.log('APPLICATION.JS :: should render highcharts is => '  + JSON.stringify(data));
                const data_series = data
                console.log('data series in success ' + data_series)
                const chart_type = data['chart_type']
                Highcharts.chart('statistics-chart-div', {
                    title: {
                        text: 'Statistikat'
                    },
                    subtitle: {
                        text: 'Paris'
                    },
                    xAxis: {
                        accessibility: {
                            rangeDescription: 'Muajt'
                        }
                    },

                    yAxis: {
                        min: 0,
                        title: {
                            text: 'euro',
                            align: 'high'
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    tooltip: {
                        valueSuffix: ' euro'
                    },
                    plotOptions: {
                        series: {
                            label: {
                                connectorAllowed: false
                            },
                            pointStart: 2010
                        }
                    },

                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'middle'
                    },
                    credits: {
                        enabled: false
                    },
                    series: data_series
                })


            }


        })
    })

    function  get_report_type() {
        var report_type = ''
        if ($('#entity_type_Institucionit').is(':checked')){
            report_type = 'institucionit'
        } else if ($('#entity_type_Qytetit').is(':checked')) {
            report_type = 'qytetit'
        } else if ($('#entity_type_Agjentit').is(':checked')) {
            report_type = 'agjentit'
        }
        return report_type;
    }

    function get_amount_or_number(){
        var amount_or_number = '';
        if($('#amount_or_number_all').is(':checked')){
            amount_or_number = 'all';
        } else if($('#amount_or_number_amount').is(':checked')){
            amount_or_number = 'amount';
        } else if ($('#amount_or_number_number').is(':checked')){
            amount_or_number = 'number';
        } else if ($('#amount_or_number_portfolio-amount').is(':checked')) {
            amount_or_number = 'portfolio-amount'
        } else if ($('#amount_or_number_collections-amount').is(':checked')) {
            amount_or_number = 'collections-amount'
        } else if ($('#amount_or_number_portfolio-number').is(':checked')) {
            amount_or_number = 'portfolio-number'
        } else if ($('#amount_or_number_collections-number').is(':checked')) {
            amount_or_number = 'collections-number'
        }
        return amount_or_number;
    }


    function draw_line_chart(data_arr) {
        google.charts.load('current', {packages: ['corechart', 'line']});
        google.charts.setOnLoadCallback(drawBasic);

        function drawBasic() {
            var data = new google.visualization.DataTable();
            console.log('header in line chart: ' + data_arr['header'])
            $.each(data_arr['header'], function(index, value){
                console.log('index: ' + index);
                console.log('value: ' + value);
                if(index == 0 ) {
                    data.addColumn('string', value)
                } else {
                    data.addColumn('number', value)
                }
            });
            data.addRows(data_arr['data'])
            var options = {
                legend: {position: 'top', maxLines: 2},
                curveType: 'function',
                height: 700
            };
            var chart = new google.visualization.LineChart(
                document.getElementById('statistics-chart-div'));
            chart.draw(data, options);
        }
    }

    function draw_column_bar_chart(data_arr) {
        console.log("data to be drawn: " + data_arr);
        console.log('column bar chart...')
        google.charts.load('current', {packages: ['corechart']});
        google.charts.setOnLoadCallback(drawBasic);

        function drawBasic() {
            var data = new google.visualization.DataTable();
            data.addColumn('string', 'Muaji');
            data.addColumn('number', 'Shuma');
            data.addColumn({type: 'string', role: 'annotation'})
            data.addColumn('number', 'Perqindja');
            data.addRows(data_arr);
            var options = {
                legend: {position: 'top', maxLines: 2},
                vAxes: {
                    0: {},
                    1: {}
                },
                height: 800,
                hAxis: {title: 'bb'},
                seriesType: 'bars',
                series: {
                    0: {targetAxisIndex: 0},
                    1: {type: 'line', targetAxisIndex: 1}
                }

            };
            var chart = new google.visualization.ComboChart(
                document.getElementById('statistics-chart-div'));
            chart.draw(data, options);
        }
    }

    function draw_column_bar_chart_new(data_arr) {
        console.log("data to be drawn: ");
        console.log('column bar chart new...')
        google.charts.load('current', {packages: ['corechart']});
        google.charts.setOnLoadCallback(drawBasic);

        function drawBasic() {
            var data = new google.visualization.DataTable();
            data.addColumn( 'string', 'month');
            data.addColumn('number', 'Shuma');
            data.addColumn({type: 'string', role: 'annotation'})
            data.addColumn('number', 'Perqindja');
            data.addRows([
                ['qer', 64646431, '66', 2],
                ['kor', 55646464, '77', 3]
            ])
            var options = {
                legend: {position: 'top', maxLines: 2},
                vAxes: {
                    0: {},
                    1: {}
                },
                height: 800,
                hAxis: {title: 'bb'},
                seriesType: 'bars',
                series: {
                    0: {targetAxisIndex: 0},
                    1: {type: 'line', targetAxisIndex: 1}
                }

            };
            var chart = new google.visualization.ComboChart(
                document.getElementById('statistics-chart-div'));
            chart.draw(data, options);
        }
    }

});