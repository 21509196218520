$( document ).on('turbolinks:load', function() {
    console.log('focus.js is loaded');
    $('#filter-focus-agent').on('change', function(){
        const agentId = $(this).val();
        console.log('you chosee agent with id ' + agentId)
        $('#filter-focus-type').attr('data-filter-focus-agent', agentId)
        $('#filter-focus-period').attr('data-filter-focus-agent', agentId)
    })

    $('#filter-assigned-by').on('change', function(){
        const assignedBy = $(this).val()
        $('#filter-focus-agent').attr('data-assigned-by', assignedBy)
        $('#filter-focus-period').attr('data-assigned-by', assignedBy)
    })

    $("#filter-focus-period").on('change', function(){
        const period = $(this).val()
        $("#filter-focus-agent").attr("data-period", period)
        $("#filter-assigned-by").attr('data-period', period)
    })


})