import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [ "clientsCount", "desiredCount" ]

    connect() {

        console.log('Hello from DashboardController', this.element);

        //this.outputTarget.textContent = 'Hello, Stimulus!'
    }
    updateClientsCount(){
        const element = this.desiredCountTarget
        const desiredNumber = element.value
        console.log(`Desired count is: ${desiredNumber}!`)
        this.clientsCountTarget.innerHTML = desiredNumber
    }
}
