import {columnsDefinitions, resolveFields, citySelectList, agentSelectList, confirmedTransactionsList, menyraKontaktimitList, banksList, datatableUrl, confirmedList, bankUpdateList} from "../helpers/datatables_helpers";
import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-ja';
const TransactionDatatable = () => {

    // begin first table
    let model = 'transaction';

    let recordsSum = 0;
    let promisedSum = 0;
    let confirmedA = 0;
    let confirmedB = 0

    const tableFields = resolveFields(model);
    console.log("TABLE FIELDS ARE: " + tableFields)

    console.log("datatableurl is: " + datatableUrl())
    let table = $('#kt_datatable').DataTable({
        responsive: true,
        autoWidth: false,
        // Pagination settings
        dom: `<'row'<'col-sm-12'tr>>
			<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,
        // read more: https://datatables.net/examples/basic_init/dom.html
        lengthMenu: [5, 10, 25, 50],
        pageLength: 10,
        order: [[0,'asc']],
        language: {
            'lengthMenu': 'Display _MENU_',
        },
        searchDelay: 500,
        processing: true,
        serverSide: true,
        ajax: {
            url: datatableUrl(),
            type: 'GET',
            data: {
                // parameters for custom backend script demo
                model: model,
                columnsDef: tableFields,
            },
            dataSrc: function(data){
                recordsSum = data.recordsSum;
                promisedSum = parseFloat(data.promisedSum);
                confirmedA = data.confirmedA;
                confirmedB = data.confirmedB;
                return data.data;
            }
        },
        drawCallback: function( settings ) {
            var api = this.api();
            $(api.column(5).footer()).html(
                '€' + numberString(promisedSum)
            )
            $( api.column( 8 ).footer() ).html(
                '€' + numberString(confirmedA)
            );
            $( api.column( 10 ).footer() ).html(
                '€' + numberString(confirmedB)
            );
        },
        columns: columnsDefinitions(model),
        initComplete: function() {
            var thisTable = this;
            var rowFilter = $('<tr class="filter"></tr>').appendTo($(table.table().header()));

            this.api().columns().every(function() {

                var column = this;
                console.log("column is " + column)
                var input;

                switch (column.title()) {
                    case 'Emri':
                        input = $(`<input type="text" class="form-control form-control-sm form-filter datatable-input" data-col-index="` + column.index() + `"/>`);
                        break;
                    case 'Muaji':
                        input = $(`<input type="text" class="form-control form-control-sm form-filter datatable-input" value="10" data-col-index="` + column.index() + `"/>`);
                        break;
                    case 'Viti':
                        input = $(`<input type="text" class="form-control form-control-sm form-filter datatable-input" value="2022" data-col-index="` + column.index() + `"/>`);
                        break;
                    case 'Nr. Borxhit':
                        input = $(`<input type="text" class="form-control form-control-sm form-filter datatable-input" data-col-index="` + column.index() + `"/>`);
                        break;
                    case 'Konfirmuar':
                        input = confirmedList(column)
                        break;
                    case 'Banka':
                        input = banksList(column)
                        break;
                    case 'Dite pa kontakt':
                        input = $(`<input type="text" class="form-control form-control-sm form-filter datatable-input" data-col-index="` + column.index() + `"/>`);
                        break;
                    case 'Qyteti':
                        input = citySelectList(column);
                        break;
                    case 'Agjenti':
                        input = agentSelectList(column);
                        break;
                    case 'Data Premtimit':
                        input = $(`
    							<div class="input-group date">
    								<input type="text" class="form-control form-control-sm datatable-input" readonly placeholder="Prej" id="kt_datepicker_1"
    								 data-col-index="` + column.index() + `"/>
    								<div class="input-group-append">
    									<span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span>
    								</div>
    							</div>
    							<div class="input-group date d-flex align-content-center">
    								<input type="text" class="form-control form-control-sm datatable-input" readonly placeholder="Deri" id="kt_datepicker_2"
    								 data-col-index="` + column.index() + `"/>
    								<div class="input-group-append">
    									<span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span>
    								</div>
    							</div>`);
                        break;
                    case 'Data Premtuar':
                        input = $(`
    							<div class="input-group date">
    								<input type="text" class="form-control form-control-sm datatable-input" readonly placeholder="Prej" id="kt_datepicker_1"
    								 data-col-index="` + column.index() + `"/>
    								<div class="input-group-append">
    									<span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span>
    								</div>
    							</div>
    							<div class="input-group date d-flex align-content-center">
    								<input type="text" class="form-control form-control-sm datatable-input" readonly placeholder="Deri" id="kt_datepicker_2"
    								 data-col-index="` + column.index() + `"/>
    								<div class="input-group-append">
    									<span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span>
    								</div>
    							</div>`);
                        break;
                    case 'Data Konfirmimit':
                        input = $(`
    							<div class="input-group date">
    								<input type="text" class="form-control form-control-sm datatable-input" readonly placeholder="Prej" id="kt_datepicker_1"
    								 data-col-index="` + column.index() + `"/>
    								<div class="input-group-append">
    									<span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span>
    								</div>
    							</div>
    							<div class="input-group date d-flex align-content-center">
    								<input type="text" class="form-control form-control-sm datatable-input" readonly placeholder="Deri" id="kt_datepicker_2"
    								 data-col-index="` + column.index() + `"/>
    								<div class="input-group-append">
    									<span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span>
    								</div>
    							</div>`);
                        break;
                    case 'Data Konfirmimit B':
                        input = $(`
    							<div class="input-group date">
    								<input type="text" class="form-control form-control-sm datatable-input" readonly placeholder="Prej" id="kt_datepicker_1"
    								 data-col-index="` + column.index() + `"/>
    								<div class="input-group-append">
    									<span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span>
    								</div>
    							</div>
    							<div class="input-group date d-flex align-content-center">
    								<input type="text" class="form-control form-control-sm datatable-input" readonly placeholder="Deri" id="kt_datepicker_2"
    								 data-col-index="` + column.index() + `"/>
    								<div class="input-group-append">
    									<span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span>
    								</div>
    							</div>`);
                        break;
                    case 'Actions':
                        var search = $(`
                                <button class="btn btn-primary kt-btn btn-sm kt-btn--icon d-block col-md-12 col-lg-12">
							        <span>
							            <i class="fa fa-search"></i>
							            <span>Filtro</span>
							        </span>
							    </button>`);

                        var reset = $(`
                                <button class="btn btn-secondary kt-btn btn-sm kt-btn--icon col-md-12 col-lg-12" >
							        <span>
							           <i class="fa fa-close"></i>
							           <span>Reset</span>
							        </span>
							    </button>`);

                        $('<th>').append(search).append(reset).appendTo(rowFilter);

                        $(search).on('click', function(e) {
                            e.preventDefault();
                            var params = {};
                            $(rowFilter).find('.datatable-input').each(function() {
                                var i = $(this).data('col-index');
                                if (params[i]) {
                                    params[i] += '|' + $(this).val();
                                } else {
                                    params[i] = $(this).val();
                                }
                            });
                            $.each(params, function(i, val) {
                                // apply search params to datatable
                                table.column(i).search(val ? val : '', false, false);
                            });
                            table.table().draw();
                        });

                        $(reset).on('click', function(e) {
                            e.preventDefault();
                            $(rowFilter).find('.datatable-input').each(function(i) {
                                $(this).val('');
                                table.column($(this).data('col-index')).search('', false, false);
                            });
                            table.table().draw();
                        });
                        break;
                }

                if (column.title() !== 'Actions') {
                    $(input).appendTo($('<th>').appendTo(rowFilter));
                }
            });

            // hide search column for responsive table
            var hideSearchColumnResponsive = function() {
                thisTable.api().columns().every(function() {
                    var column = this
                    if (column.responsiveHidden()) {
                        $(rowFilter).find('th').eq(column.index()).show();
                    } else {
                        $(rowFilter).find('th').eq(column.index()).hide();
                    }
                })
            };

            // init on datatable load
            hideSearchColumnResponsive();
            // recheck on window resize
            window.onresize = hideSearchColumnResponsive;

            $('#kt_datepicker_1,#kt_datepicker_2').datepicker({
                autoclose: true
            });
        },
        columnDefs:  [{
            targets: -1,
            width: '100px',
            title: 'Actions',
            orderable: false,
        },
            {
                targets:0,
                width: '250px'
            },
            {
                targets: 1,
                width: '100px',

            },
            {
                targets: 2,
                width: '160px',
            },
            {
                targets: 3,
                width: '30px',
            },
            {
                targets: 4,
                width: '40px',
                className: 'text-right'
            },
            {
                targets: 5,
                width: '80px',
                className: 'text-right'
            },
            {
                targets: 6,
                width: '100px',
                className: 'text-center'
            },
            {
                targets: 7,
                width: '100px',
                className: 'text-center'
            },
            {
                targets: 8,
                width: '80px',
                className: 'text-right'
            },
            {
                targets: 9,
                width: '100px',
               className: 'text-center'
            },
            {
                targets: 10,
                width: '80px',
                className: 'text-right'
            },
            {
                targets: 11,
                width: '100px',
                className: 'text-center'
            }

        ]
    });

    return table;
}


const numberString = (nStr) => {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}







export default TransactionDatatable;