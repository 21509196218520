import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import throttle from 'lodash/throttle'

export default class extends Controller {
    static targets = ["search"]

    connect () {
        StimulusReflex.register(this)
        console.log('JAVASCRIPT :: CONTROLLERS :: SEARCH _CONTROLLER ')
        //this.throttleKeydown = throttle(this.throttleKeydown, 1000)
    }

    keyup(event){
        console.log('keyup is here!')
    }

    keydown (event) {
        console.log('keydown ' + JSON.stringify(event))
        console.log('event key  is' + event.key)
        event.which === 13
            ? this.doSomethingWithEnter(event)
            : this.otherOne(event)

        //event.repeat
        //    ? this.throttleKeydown(event)
        //    : this.stimulate('Event#keydown', event.key)
    }

    doSomethingWithEnter(event) {
        console.log('moved to doSomethingWithEnter...')
        let term = this.searchTarget.value
        console.log('searched term in throttleKeywor: ' + term);
        this.stimulate('Search#search', term)
    }

    otherOne(event){
        console.log('this is other one...')
    }

    throttleKeydown (event) {

        this.stimulate('Event#keydown', term)
    }
}