// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('datatables.net-bs4')
require('datatables.net-responsive-bs4')
require('../packs/datatables.responsive.js')
require('../packs/main')
require('../packs/scripts.bundle');
require('bootstrap-datepicker');
require('select2');
require('datatables.net-bs4')
require('datatables.net-responsive-bs4')
require("jquery.scrollbar")
require("datatables.net-autofill")
require("../packs/column-search");
require("../packs/notifications")
require("./ExportUrlComposer");
require("./statistics")
require("./focus")

// To enable this import I had to follow so question https://stackoverflow.com/questions/44294969/installing-modernizr-with-yarn-on-rails
//import modernizr from 'modernizr';

//const modernizr = require("modernizr");

import jQuery from 'jquery';

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;


import "bootstrap";
import "@fortawesome/fontawesome-free/css/all.css";

import "../scss/main.scss"


import "controllers"
import "select2"
import "select2/dist/css/select2.css";

import Highcharts from 'highcharts';

window.PerfectScrollbar = require('perfect-scrollbar').default;


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


$( document ).on('turbolinks:load', function() {
    console.log('this is document load of application js');
    $('#kt_body').addClass('aside-minimize')

    $('.select2').select2();
    // Enable tooltip everywhere...
    $('[data-toggle="tooltip"]').tooltip()

    let modalBodyRow = $('#modal-body-row');
    if(modalBodyRow != undefined){
        let screenWidth = screen.width;
        //console.log('Screen width is: ' + screenWidth)
        //modalBodyRow.css('width', screenWidth - 300)
        //$('#client-modal').css('width', screenWidth - 200);
        //$('.modal-content').css('width', screenWidth - 200);
        //$('.modal-dialog').css('margin', '0px')

    }

    $('#plain-datatable').DataTable({ searching: false, bLengthChange: false})


    $(document).on('click', '.nav-link', function(){
        const type = $(this).attr('data-type');
        console.log('clicked nav link....'  + type)
        $('#filter-focus-client').attr('data-displayed-focuses-type', `${type}`)
        $('#filter-focus-type').attr('data-displayed-focuses-type', `${type}`)
    })
    $(document).on('click', '.debt-contact-checkbox', function(){
        let debtId = $(this).attr('data-debt-id');
        let $this = $(this)
        console.log('you clicked checkbox with id: ' + debtId);
        let divToShow = '#debt-'
        divToShow += debtId.toString();
        divToShow += "-contact-fields"
        if($this.is(":checked")){
            $(divToShow).css('display', 'block')
        } else {
            $(divToShow).css('display', 'none')
        }
    })

    $(document).on('click', '.debt-contact-payment-checkbox', function(){
        let debtId = $(this).attr('data-debt-id')
        let $this = $(this);
        let divToShow = '#debt-'
        divToShow += debtId.toString();
        divToShow += '-payment-fields'
        if($this.is(":checked")){
            $(divToShow).css('display', 'block')
        } else {
            $(divToShow).css('display', 'none')
        }
    })

    $(document).on('click', '.display-confirm-payment', function(){
        let transactionId = $(this).attr('data-transaction-id');
        $(`#confirm-${transactionId}`).css('display', 'block')
    })


    let mapDiv = $('#map');
    if(Object.keys(mapDiv).length > 0){
        let lat = parseFloat(mapDiv.attr('data-lat'));
        let long = parseFloat(mapDiv.attr('data-long'));
        let client = mapDiv.attr('data-client-name')
        console.log('map div is here lets load the map now, with lat: ' + lat + ', and long: ' + long );
        const myLatLng = { lat: lat, lng: long };
        const map = new google.maps.Map(document.getElementById("map"),{
            zoom: 12,
            center: myLatLng
        });
        new google.maps.Marker({
            position: myLatLng,
            map,
            title: client,
        });

    }

    $('.show-notification').click(function(e) {
        console.log('show notification is clicked!')
        e.preventDefault();
        var button = $(this);
        var style = $('.btn-notification-style.active').text(); // Type of notification
        var message = $('.notification-message').val(); // Message to display inside the notification
        var type = $('select.notification-type').val(); // Info, Success, Error etc
        var position = $('.tab-pane.active .position.active').attr('data-placement'); // Placement of the notification
        console.log('style for notification is: ' + style );
        console.log('type is: ' + type)
        // Show an bar notification attached to top and bottom of the screen
        $('.page-content-wrapper').pgNotification({
            style: 'bar',
            message: message,
            position: 'top',
            timeout: 0,
            type: type
        }).show();


        e.preventDefault();
    });

    $('.position').click(function() {
        $('.pgn').remove();
        $(this).closest('.notification-positions').find('.position').removeClass('active');
        $(this).addClass('active');
    });

    $('.btn-notification-style').click(function() {
        var target = $(this).attr('data-type');
        $('#preview-tab-position a[href="#'+target+'"]').tab('show')
    });

    // remove previously added notifications from the screen
    $('a[data-toggle="tab"]').on('show.bs.tab', function(e) {
        var position = $(this).data('type');
        $('a[href="'+position+'"]').tab('show')
        $('.pgn').remove();
    });

    $('body').on('change', '.choose-timeline-context', function(){
        let context = $(this).val();
        let button = $('#apply-timeline-filter-btn')
        console.log('CONTEXT IS: ' + context);
        button.attr('data-context', context);
    })

    $('body').on('change', '.choose-timeline-period', function(){
        let period = $(this).val();
        let button = $('#apply-timeline-filter-btn')
        console.log("PERIOD IS: " + period)
        button.attr('data-period', period);
    })

    $('.chart-grouping-type').on('change', function(){
        let grouping = $(this).attr('data-type');
        $('#display-chart-button').attr('data-grouping', grouping)
    })

    $('.chart-data-type').on('change', function(){
        let dataType = $(this).attr('data-type');
        $('#display-chart-button').attr('data-data-type', dataType);
    })
    $('.chart-data-context').on('change', function(){
        let context = $(this).attr('data-type');
        console.log('context changed to ' + context);
        $('#display-chart-button').attr('data-context', context);
    })

    $('#chart-agent').on('change', function() {
        let userId = $(this).val();
        $('#display-chart-button').attr('data-agent-id', userId);
    })

    $('.chart-data-period').on('change', function(){
        let period = $(this).attr('data-period');
        console.log("period to display is " + period);
        $('#display-chart-button').attr('data-period', period)
    })


    $('#display-chart-button').on('click', function(){
        let userId = $('#chart-agent').val();
        let dataType = $(this).attr('data-data-type');
        let period = $(this).attr('data-period');
        let grouping = $(this).attr('data-grouping');
        let context = $(this).attr('data-context');

        $.ajax({
            method: 'GET',
            url: '/chart_data',
            data: { data_type: dataType, period: period, user_id: userId, grouping: grouping, context: context },
            success: function (data) {
                            console.log('APPLICATION.JS :: should render highcharts is => '  + JSON.stringify(data));
                            const data_series = data['data_series']
                            const chart_type = data['chart_type']
                            const xAxis = data['x-axis']
                            const plotOptions = data['plot-options']
                            const chartTitle = data['title']
                            const colors = data['colors']
                            Highcharts.chart('chart-area', {
                                chart: {
                                    type: chart_type
                                },
                                title: {
                                    text: chartTitle
                                },
                                colors: colors,
                                xAxis: {
                                    categories: xAxis,
                                    title: {
                                        text: null
                                    }
                                },
                                yAxis: {
                                    min: 0,
                                    title: {
                                        text: '',
                                        align: 'high'
                                    },
                                    labels: {
                                        overflow: 'justify'
                                    },
                                    stackLabels: {
                                        enabled: false,
                                        style: {
                                            fontWeight: 'bold',
                                            color: ( // theme
                                                Highcharts.defaultOptions.title.style &&
                                                Highcharts.defaultOptions.title.style.color
                                            ) || 'gray'
                                        }
                                    }
                                },
                                tooltip: {
                                    valueSuffix: ''
                                },
                                plotOptions: plotOptions,
                                legend: {
                                    layout: 'vertical',
                                    align: 'right',
                                    verticalAlign: 'top',
                                    x: -40,
                                    y: 80,
                                    floating: true,
                                    borderWidth: 1,
                                    backgroundColor:
                                        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                                    shadow: true
                    },
                    credits: {
                        enabled: false
                    },
                    series: data_series
                })
            }
        });
    })

    $('#display-chart-button').click();

    $(".debt-contact-payment-checkbox").on('change', function(){
        let debtId = $(this).attr('data-debt-id')
        $('.payment-amount').each(function(){
            let paymentAmountDebtId = $(this).attr('data-debt-id')
            if(debtId == paymentAmountDebtId){
                $(this).attr('required', true)
            }
        })
    })

    $("#submit-dynamic-report").on('click', function(){
        const form = document.getElementById('dynamic-report-form');
        form.addEventListener('submit', resetForm('dynamic-report-form'))
    })

    $("#submit-detailed-report").on('click', function(){
        const form = document.getElementById('detailed-report-form');
        form.addEventListener('submit', resetForm('detailed-report-form'))
    })

    $("#submit-clients-list").on('click', function(){
        const form = document.getElementById('clients-list-form');
        form.addEventListener('submit', resetForm('clients-list-form'))
    })

    $("#submit-debts-list").on('click', function(){
        const form = document.getElementById('debts-list-form');
        form.addEventListener('submit', resetForm('debts-list-form'))
    })
    
})

function resetForm(formId){
    console.log('will reset here..')
    if(formId == 'dynamic-report-form'){
        $("#submit-dynamic-report").removeAttr('disabled')
        $("#submit-dynamic-report").removeAttr('data-disable-with')
    } else if (formId == 'detailed-report-form'){
        $("#submit-detailed-report").removeAttr('disabled')
        $("#submit-detailed-report").removeAttr('data-disable-with')
    } else if (formId == 'clients-list-form'){
        $("#submit-clients-list").removeAttr('disabled')
        $("#submit-clients-list").removeAttr('data-disable-with')
    } else if (formId == 'debts-list-form'){
        $("#submit-debst-list").removeAttr('disabled')
        $("#submit-debts-list").removeAttr('data-disable-with')
    }


}



function placeMarkerAndPanTo(latLng, map) {
    new google.maps.Marker({
        position: latLng,
        map: map,
    });


    map.panTo(latLng);
}


function drawChart(chartType, userId){
    console.log("DRAW CHART :: chartType => " + chartType + ' userId => ' + userId);
    let chartDiv = resolveChartDiv(chartType, userId) //$('#dashboard-charts-div');
    console.log("dashboardChartDiv to load chart on is: " + chartDiv );
    $.ajax({
        method: 'GET',
        url: '/chart_data',
        data: { chart_type: chartType, user_id: userId},
        success: function (data) {
            console.log('APPLICATION.JS :: should render highcharts is => '  + JSON.stringify(data));
            const data_series = data['data_series']
            const chart_type = data['chart_type']
            Highcharts.chart('chart-area', {
                chart: {
                    type: chart_type
                },
                title: {
                    text: 'Portfolio Credica'
                },
                subtitle: {
                    text: 'Paris'
                },
                xAxis: {
                    categories: [''],
                    title: {
                        text: null
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'euro',
                        align: 'high'
                    },
                    labels: {
                        overflow: 'justify'
                    }
                },
                tooltip: {
                    valueSuffix: ' euro'
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'top',
                    x: -40,
                    y: 80,
                    floating: true,
                    borderWidth: 1,
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                    shadow: true
                },
                credits: {
                    enabled: false
                },
                series: data_series
            })


        }
    });

}





function resolveChartDiv(chartType, userId){
    let div = '';
    if(userId == undefined){
        div = `${chartType}-chart-area`;
    } else {
        div = `agent-${chartType}-chart-area`
    }
    return div;
}



$(document).on('click', '.choose-action', function(){
    let chosenAction = $(this).val();
    if(chosenAction == 'comment'){
        $('#focus-commend-form-field').css('display', 'block')
    }
    let parent = $(this).closest('.row');
    let button = parent.find('.btn-primary')
    button.attr('disabled', false)
    button.attr('data-focus-action', chosenAction);

})

$(document).on('turbolinks:click', function(){
    console.log('this is turbolinks click')
    Turbolinks.visit();
})



$(document).on('page:fetch', function(){
    console.log('this is page:fetch')
})
// Do stuff while the fetch is starting

$(document).on('page:receive', function(){
    console.log('this is page:receive')
})
// It's almost done

$(document).on('page:change', function(){
    console.log('this is page:change')
})
// It's done

$(document).on('page:restore', function(){
    console.log('this is page:restore')
})
// It's really done





