$(document).on('turbolinks:load', function() {
    console.log('export url composer...')
    let model = $('#context').attr('data-model');
    console.log('model from context is ' + model )
    if(model == 'client' || model == 'debt'){
        updateLocalStorage('status', 'active')
    }
    if(model == 'transaction'){
        updateLocalStorage('bankUpdate', false)
        updateLocalStorage( 'bankId', '')
    }
    updateLocalStorage('model', model)
    updateExportButtonHref()

    $('body').on('change', '.form-filter', function(){
        let index = $(this).attr('data-col-index');
        console.log('colindex is ' + index);
        let selectedValue= $(this).val();
        console.log('value is ' + selectedValue);
        if(model == 'transaction') {
            if(index == 5){
                updateLocalStorage('bankUpdate', selectedValue)
            } else if (index == 1){
                updateLocalStorage( 'bankId', selectedValue)
            }
        } else {
            console.log('will process selected option...')
            processSelectedOption(index, selectedValue)
        }
        updateExportButtonHref();
    })

    $('body').on('change', '#kt_datepicker_1', function(){
        localStorage.setItem('from', $(this).val())
        updateExportButtonHref();
    })

    $('body').on('change', '#kt_datepicker_2', function(){
        localStorage.setItem('until', $(this).val())
        updateExportButtonHref();
    })
});


function processSelectedOption(index, selectedValue){
    let model = localStorage.getItem('model')
    console.log('model in processSelectedOption is ' + model);
    console.log('index in processSelectedOption is ' + index);
    if(model == 'client'){
        if(index == 10){
            console.log('will set value ' + selectedValue)
            localStorage.setItem('status', statusValueToString(selectedValue))
        }
    } else if (model == 'debt'){
        if(index == 7){
            localStorage.setItem('status', statusValueToString(selectedValue))
        }
    } else if (model == 'transaction'){
        if (index == 5){
            localStorage.setItem('bank_update', selectedValue)
        }
    }
}

function statusValueToString(value){
    let status = 'active'
    if(value == 2){
        status = 'completed'
    } else if (value == 3) {
        status = 'pulled'
    } else if (value == 4) {
        status = 'deleted'
    } else {}
    return status;
}

function updateLocalStorage(attr, value){
    localStorage.setItem(attr, value)
}

function composeHref(){
    const model = localStorage.getItem('model')
    let href = `/${model}s/export?`;
    if(model == 'client' || model == 'debt'){
        let status = localStorage.getItem('status')
        href += `status=${status}`
    } else if (model == 'contact'){
        let from = localStorage.getItem('from')
        href += `from=${from}`
        let until = localStorage.getItem('until')
        href += `&until=${until}`
    } else if (model == 'transaction'){
        let from = localStorage.getItem('from')
        href += `from=${from}`
        let until = localStorage.getItem('until')
        href += `&until=${until}`
        let bankUpdate = localStorage.getItem('bankUpdate')
        href += `&bank_update=${bankUpdate}`
        let bankId = localStorage.getItem('bankId')
        href += `&bank_id=${bankId}`
    }
    console.log('href to set is => ' + href )
    return href;
}

function updateExportButtonHref(){
    let model = localStorage.getItem('model')
    console.log("UPDATEEXPORT BUTTON MODEL IN LOCAL STORAGE => " + model)
    let button = `#export-${model}s-btn`
    let href = composeHref();
    $(button).attr('href', href)
}