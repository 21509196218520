"use strict";

import { columnsDefinitions} from "../helpers/datatables_helpers";
import ClientDatatable from "./ClientDatatable";
import DebtDatatable from "./DebtDatatable";
import ContactDatatable from "./ContactDatatable";
import TransactionDatatable from "./TransactionDatatable";
import ReportDatatable from "./ReportDatatable";
import ProspectDatatable from "./ProspectDatatable";


var KTDatatablesSearchOptionsColumnSearch = function() {

    const datatableUrl = 'http://localhost:3000/datatable'
    //const datatableUrl = 'https://doors.spektre.ch/datatable'

    $.fn.dataTable.Api.register('column().title()', function() {
        return $(this.header()).text().trim();
    });

    let initiateClientTable = () => {


    };

    return {

        //main function to initiate the module
        init: function() {
            //console.log("THIS IS INIT OF COLUMN SEARCH WILL CALL INITTABLE 1")
            const model = $('#context').attr('data-model');
            if(model == 'client'){
                ClientDatatable();
            } else if(model == 'debt'){
                DebtDatatable();
            } else if(model == 'contact'){
                ContactDatatable();
            } else if(model == 'transaction'){
                TransactionDatatable();
            } else if(model == 'report'){
                ReportDatatable();
            } else if(model == 'prospect'){
                ProspectDatatable();
            }

        },

    };

    let dtblediv = $("#kt_datatable")
    if(dtblediv){
        $('#kt_datatable').css('width', '100%')
    }

}();

function columnsWidths(model, thisTable){
    //console.log("COLUMN-SEARCH :: columnWidths :: data => " + JSON.stringify(thisTable))
    let defs = '';
    if(model == 'client'){
        defs = [{
            targets: -1,
            title: 'Actions',
            orderable: false,
            render: function(data, type, full, meta) {
                return '\
							<div class="dropdown dropdown-inline">\
								<a href="javascript:;" class="btn btn-sm btn-clean btn-icon" data-toggle="dropdown">\
	                                <i class="la la-cog"></i>\
	                            </a>\
							  	<div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">\
									<ul class="nav nav-hoverable flex-column">\
							    		<li class="nav-item"><a class="nav-link" href="#"><i class="nav-icon la la-edit"></i><span class="nav-text">Edit Details</span></a></li>\
							    		<li class="nav-item"><a class="nav-link" href="#"><i class="nav-icon la la-leaf"></i><span class="nav-text">Update Status</span></a></li>\
							    		<li class="nav-item"><a class="nav-link" href="#"><i class="nav-icon la la-print"></i><span class="nav-text">Print</span></a></li>\
									</ul>\
							  	</div>\
							</div>\
							<a href="javascript:;" class="btn btn-sm btn-clean btn-icon" title="Edit details">\
								<i class="la la-edit"></i>\
							</a>\
							<a href="javascript:;" class="btn btn-sm btn-clean btn-icon" title="Delete">\
								<i class="la la-trash"></i>\
							</a>\
						';
            },
        },
            {
                targets: 0,
                width: '200px'
            },
            {
                targets: 1,
                width: '500px'
            },
            {
                targets: 2,
                width: '100px'
            }
        ]
    }
    return defs
}





$(document).on('turbolinks:load', function() {
    //console.log('we are in KTDAtatablesomething...')
    KTDatatablesSearchOptionsColumnSearch.init();
});

jQuery(document).ready(function() {

});
