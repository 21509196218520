import ApplicationController from './application_controller'
import Highcharts from "highcharts";

/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
    /*
     * Regular Stimulus lifecycle methods
     * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
     *
     * If you intend to use this controller as a regular stimulus controller as well,
     * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
     *
     * Important:
     * By default, StimulusReflex overrides the -connect- method so make sure you
     * call super if you intend to do anything else when this controller connects.
    */
    filterFocus(){
        console.log('will try to filter the focus...')
    }

    connect () {
        super.connect()

        console.log('this is connect of statics controller.js')
    }


    /* Reflex specific lifecycle methods.
     *
     * For every method defined in your Reflex class, a matching set of lifecycle methods become available
     * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
     * need them.
     *
     * Important:
     * Make sure to add data-controller="example" to your markup alongside
     * data-reflex="Example#dance" for the lifecycle methods to fire properly.
     *
     * Example:
     *
     *   <a href="#" data-reflex="click->Example#dance" data-controller="example">Dance!</a>
     *
     * Arguments:
     *
     *   element - the element that triggered the reflex
     *             may be different than the Stimulus controller's this.element
     *
     *   reflex - the name of the reflex e.g. "Example#dance"
     *
     *   error/noop - the error message (for reflexError), otherwise null
     *
     *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
     */

    // Assuming you create a "Example#dance" action in your Reflex class
    // you'll be able to use the following lifecycle methods:

    // beforeDance(element, reflex, noop, reflexId) {
    //  element.innerText = 'Putting dance shoes on...'
    // }

    // danceSuccess(element, reflex, noop, reflexId) {
    //   element.innerText = 'Danced like no one was watching! Was someone watching?'
    // }
    drawSuccess(element, reflex, noop, reflexId){
        $('.chart-container').each(function() {
            let divId = $(this).attr('id')
            let toParse = $(this).attr('data-chart-data')
            let chartTitle = $(this).attr('data-chart-title')
            let chartType = $(this).attr('data-chart-type')
            let xAxisCategories = ['Jan','Feb','Mar', 'Apr', 'May']
            let dataSeries = JSON.parse(toParse)
            let chartDivId = `${chartType}`
            let plotOptions = JSON.parse($(this).attr('data-plot-options'))
            let yAxis = JSON.parse($(this).attr('data-y-axis'))
            let xAxis = JSON.parse($(this).attr('data-x-axis'))
            console.log("Yaxis data is " + JSON.stringify(yAxis))
            console.log("xAxis data is " + JSON.stringify(xAxis))
            console.log("Data series is " + JSON.stringify(dataSeries))
            console.log("Plot options is " + JSON.stringify(plotOptions))
            function drawColumnChart(divId, chartTitle, xAxisCategories, dataSeries, plotOptions, xAxis, yAxis) {
                Highcharts.chart(divId, {
                    chart: {
                        zoomType: 'xy',
                        type: 'column',
                    },
                    title: {
                        text: chartTitle
                    },
                    xAxis: xAxis,
                    yAxis: yAxis,
                    plotOptions: plotOptions,
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    series: dataSeries
                });
            }

            if(chartType === 'column'){
                drawColumnChart(divId, chartTitle, xAxisCategories, dataSeries, plotOptions, xAxis, yAxis)
            } else if (chartType == 'line'){
                outer.append(`<div id="${chartDivId}" style="width:100%; height:100%;"></div>`)
                this.drawLineChart(divId, chartTitle, xAxisCategories, dataSeries)
            }
        })
    }

    drawLineChart(chartDivId, chartTitle, xAxisCategories, dataSeries){
        Highcharts.chart(chartDivId, {
            chart:{
                height: 700,
            },
            title: {
                text: chartTitle
            },
            subtitle: {
                text: 'Paris'
            },
            xAxis: {
                accessibility: {
                    rangeDescription: 'Muajt'
                },
                categories: xAxisCategories
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'euro',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ' euro'
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    }
                }
            },

            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },
            credits: {
                enabled: false
            },
            series: dataSeries
        })
    }

    // danceError(element, reflex, error, reflexId) {
    //   console.error('danceError', error);
    //   element.innerText = "Couldn't dance!"
    // }

}