import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import openModal from "../packs/openModal";

/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect () {
    //console.log('JAVASCRIPT :: CONTROLLERS :: APPLICATION CONTROLLER ')
    StimulusReflex.register(this)

  }



  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  beforeReflex (element, reflex, noop, reflexId) {
    // document.body.classList.add('wait')
  }

  reflexSuccess (element, reflex, noop, reflexId) {
    // show success message etc...
  }

  reflexError (element, reflex, error, reflexId) {
    // show error message etc...
  }

  afterReflex (element, reflex, noop, reflexId) {
     //document.body.classList.remove('wait')
    console.log('reflex is: ' + reflex)
    if(reflex == 'FocusReflex#display_modal'){
      openModal('client')
    } else if (reflex == 'DashboardReflex#add_to_focus'){
      displayNotification('Rasti u shtua ne fokus', 'success')
    } else if (reflex == 'ClientsReflex#edit_focus'){

    } else if (reflex == 'ContactsReflex#create'){
      $('#contact-form').css("display", 'none')
      displayNotification('Kontakti u shtua me sukses', 'success')


    } else if (reflex == 'DashboardReflex#load_focus'){
      console.log('here I should hide ')
      $('.notification-toggle').removeClass('show');
      $([document.documentElement, document.body]).animate({
        scrollTop: $("#focus-row").offset().top
      }, 2000);
    }

  }
}

function displayNotification(message, type){
  $('.page-content-wrapper').pgNotification({
    style: 'bar',
    message: message,
    position: 'top',
    timeout: 0,
    type: type
  }).show();
}