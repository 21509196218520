import {
    columnsDefinitions,
    resolveFields,
    datatableUrl,
    banksList,
    statusesList,
    judicialStatusesList,
    citySelectList, agentSelectList
} from "../helpers/datatables_helpers";

const DebtDatatable = () => {

    //const model = $('#context').attr('data-model');
    //console.log('DT MODEL IS: ' + model);
    const model = 'debt'
    const tableFields = resolveFields(model);
    //console.log("TABLE FIELDS ARE: " + tableFields)
    // begin first table
    let recordsSum = 0
    //console.log("datatableurl is: " + datatableUrl())
    let table = $('#kt_datatable').DataTable({
        responsive: true,
        // Pagination settings
        dom: `<'row'<'col-sm-12'tr>>
			<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,
        // read more: https://datatables.net/examples/basic_init/dom.html
        lengthMenu: [5, 10, 25, 50],
        pageLength: 10,
        language: {
            'lengthMenu': 'Display _MENU_',
        },
        searchDelay: 500,
        processing: true,
        serverSide: true,
        ajax: {
            url: datatableUrl(),
            type: 'GET',
            data: {
                // parameters for custom backend script demo
                model: model,
                columnsDef: tableFields,
            },
            dataSrc: function(data){
                recordsSum = data.recordsSum;
                return data.data;
            }
        },
        drawCallback: function( settings ) {
            var api = this.api();

            $( api.column( 5 ).footer() ).html(
                '€' + numberString(recordsSum)
            );
        },
        columns: columnsDefinitions(model),
        initComplete: function() {
            var thisTable = this;
            var rowFilter = $('<tr class="filter"></tr>').appendTo($(table.table().header()));

            this.api().columns().every(function() {
                var column = this;
                var input;

                switch (column.title()) {
                    case 'Emri':
                    case 'Numri Borxhit':
                    case 'Borxhi aktiv':
                    case 'Telefoni':
                        input = $(`<input type="text" class="form-control form-control-sm form-filter datatable-input" data-col-index="` + column.index() + `"/>`);
                        break;
                    case 'Agjenti':
                        input = agentSelectList(column);
                        break;
                    case 'Qyteti':
                        input = citySelectList(column);
                        break;
                    case 'Banka':
                        input = banksList(column)
                        break;
                    case 'Statusi':
                        input = statusesList(column);
                        break;
                    case 'Statusi Juridik':
                        input = judicialStatusesList(column);
                        break;
                    case 'City':
                        input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
                        column.data().unique().sort().each(function(d, j) {
                            $(input).append('<option value="' + d + '">' + d + '</option>');
                        });
                    case 'Phone':
                        input = $(`<input type="text" class="form-control form-control-sm form-filter datatable-input" data-col-index="` + column.index() + `"/>`);
                        break;
                    case 'KostenProStuck':
                        input = $(`<input type="text" class="form-control form-control-sm form-filter datatable-input" data-col-index="` + column.index() + `"/>`);
                        break;

                    case 'Country':
                        input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
                        column.data().unique().sort().each(function(d, j) {
                            $(input).append('<option value="' + d + '">' + d + '</option>');
                        });
                        break;
                    case 'Ship Date':
                        input = $(`
    							<div class="input-group date">
    								<input type="text" class="form-control form-control-sm datatable-input" readonly placeholder="From" id="kt_datepicker_1"
    								 data-col-index="` + column.index() + `"/>
    								<div class="input-group-append">
    									<span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span>
    								</div>
    							</div>
    							<div class="input-group date d-flex align-content-center">
    								<input type="text" class="form-control form-control-sm datatable-input" readonly placeholder="To" id="kt_datepicker_2"
    								 data-col-index="` + column.index() + `"/>
    								<div class="input-group-append">
    									<span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span>
    								</div>
    							</div>`);
                        break;

                    case 'Actions':
                        var search = $(`
                                <button class="btn btn-primary kt-btn btn-sm kt-btn--icon d-block col-md-12 col-lg-12">
							        <span>
							            <i class="fa fa-search"></i>
							            <span>Filtro</span>
							        </span>
							    </button>`);

                        var reset = $(`
                                <button class="btn btn-secondary kt-btn btn-sm kt-btn--icon col-md-12 col-lg-12" >
							        <span>
							           <i class="la la-close"></i>
							           <span>Reset</span>
							        </span>
							    </button>`);

                        $('<th>').append(search).append(reset).appendTo(rowFilter);

                        $(search).on('click', function(e) {
                            e.preventDefault();
                            var params = {};
                            $(rowFilter).find('.datatable-input').each(function() {
                                var i = $(this).data('col-index');
                                if (params[i]) {
                                    params[i] += '|' + $(this).val();
                                } else {
                                    params[i] = $(this).val();
                                }
                            });
                            $.each(params, function(i, val) {
                                // apply search params to datatable
                                table.column(i).search(val ? val : '', false, false);
                            });
                            table.table().draw();
                        });

                        $(reset).on('click', function(e) {
                            e.preventDefault();
                            $(rowFilter).find('.datatable-input').each(function(i) {
                                $(this).val('');
                                table.column($(this).data('col-index')).search('', false, false);
                            });
                            table.table().draw();
                        });
                        break;
                }

                if (column.title() !== 'Actions') {
                    $(input).appendTo($('<th>').appendTo(rowFilter));
                }
            });

            // hide search column for responsive table
            var hideSearchColumnResponsive = function() {
                thisTable.api().columns().every(function() {
                    var column = this
                    if (column.responsiveHidden()) {
                        $(rowFilter).find('th').eq(column.index()).show();
                    } else {
                        $(rowFilter).find('th').eq(column.index()).hide();
                    }
                })
            };

            // init on datatable load
            hideSearchColumnResponsive();
            // recheck on window resize
            window.onresize = hideSearchColumnResponsive;

            $('#kt_datepicker_1,#kt_datepicker_2').datepicker();
        },
        columnDefs:  [{
                targets: -1,
                width: '100px',
                title: 'Actions',
                orderable: false,

            },
            {
                targets:0,
                width: '150px',
                orderable: false,
            },
            {
                targets: 1,
                width: '150px',
                orderable: false
            },
            {
                targets: 2,
                width: '120px',
                orderable: false,
            },
            {
                targets: 3,
                width: '100px',
                orderable: false
            },
            {
                targets: 4,
                width: '130px',
                orderable: false,
            },
            {
                targets: 5,
                width: '100px',
            },
            {
                targets: 6,
                width: '140px',
                orderable: false
            },
            {
                targets: 7,
                width: '100px',
                orderable: false
            },
            {
                targets: 8,
                width: '200px',
                orderable: false
            },
        ]
    });

    return table;
}

const numberString = (nStr) => {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}







export default DebtDatatable;