import ApplicationController  from "./application_controller"

export default class extends ApplicationController {

    connect() {

        console.log('Hello from ClientsController', this.element);

        //this.outputTarget.textContent = 'Hello, Stimulus!'

    }

    static showCodebtors = (event) => {
        this.stimulate("ClientsReflex#display_codebtors", event.target)
    }




}
